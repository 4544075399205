import {
  LineChart as Pr_LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

function LineChart({ width, height, data, name }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {data.length > 0 ? (
        <Pr_LineChart
          width={width ?? 500}
          height={height ?? 300}
          data={data}
          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid y={0} vertical={false}  /> */}
          <XAxis
            tickLine={false}
            axisLine={false}
            allowDecimals={false}
            dataKey="name"
          />
          <YAxis
            padding={{ bottom: 20 }}
            interval="preserveEnd"
            tickLine={false}
            axisLine={false}
            allowDecimals={false}
            domain={[
              (dataMin) => dataMin - dataMin / 10,
              (dataMax) => dataMax + dataMax / 10,
            ]}
          />
          <Tooltip cursor={false} />
          {/* <Legend /> */}
          <Line
            strokeWidth={1.5}
            legendType="plainline"
            type="monotone"
            dataKey="value"
            stroke="#4C399F"
            name={name ?? "Valor"}
            activeDot={{ r: 5 }}
          />
        </Pr_LineChart>
      ) : (
        <h6 style={{ marginTop: "3vh" }}>No hay datos disponibles</h6>
      )}
    </div>
  );
}

export { LineChart };
