import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

import BootstrapTable from "react-bootstrap/Table";

import { useTable, useSortBy } from "react-table";

const ContentTable = ({ columns, data = [] }) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <div className="table-responsive">
      <BootstrapTable
        striped
        borderless
        bordered
        hover
        size="sm"
        {...getTableProps()}
        className="content-table__table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="content-table__th"
                >
                  {column.render("Header")}
                  <span
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "caret-4-desc"
                          : "caret-4-asc"
                        : "order-4"
                    }
                  ></span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="text-nowrap content-table__table-row"
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>
    </div>
  );
};

export { ContentTable };
