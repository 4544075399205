import { useEffect, useState } from "react";
import useOrganizations from "../../../hooks/useOrganizations";
import { ContentTable } from "../components/ContentTable";
import { Badge } from "react-bootstrap";
import { XCircleFill, PatchCheckFill } from "react-bootstrap-icons";

import "../styles.css";

const Organizations = () => {
  const { getResume, loading, error, message, data } = useOrganizations();
  const [hideInactive, setHideInactive] = useState(true);

  useEffect(getResume, []);

  return (
    <>
      <section className="dashboard-section__container">
        <div className="content-table__header">
          <h2 className="content-table__title">Lista de Empresas</h2>
        </div>

        <div>
          <label>
            <input
              type="checkbox"
              checked={hideInactive}
              onChange={() => {
                setHideInactive((p) => !p);
              }}
            />
            Ocultar inactivas
          </label>{" "}
        </div>
        <ContentTable
          columns={[
            {
              Header: "ID",
              accessor: "id",
            },
            {
              Header: "Nombre",
              accessor: ({ createdAt, name }) => {
                if (createdAt > new Date().valueOf() / 1000 - 604800)
                  return (
                    <>
                      {name} <Badge bg="info">Nueva!</Badge>
                    </>
                  );

                return name;
              },
            },
            {
              Header: "Verificada",
              accessor: ({ verified }) => {
                if (verified) return <PatchCheckFill color="#4C399F" />;

                return <XCircleFill color="#df4759" />;
              },
            },
            {
              Header: "Empleados",
              accessor: "employees",
            },
            {
              Header: "Empleados registrados",
              accessor: "registered_employees",
              canSort: false,
            },
            {
              Header: "En servicio",
              accessor: "service_employees",
            },
            {
              Header: "Registros (7 dias)",
              accessor: "last_registers",
            },
            {
              Header: "Registros (hoy)",
              accessor: "today_registers",
            },
            {
              Header: "Registros totales",
              accessor: "registers",
            },
            {
              Header: "Administradores",
              accessor: "users",
            },
            {
              Header: "Dueño",
              accessor: "owner",
            },
            {
              Header: "Telefono",
              accessor: "phone",
            },
            {
              Header: "Donde",
              accessor: "where",
            },
            {
              Header: "Tour",
              accessor: "tour_finished",
            },
          ]}
          data={
            hideInactive
              ? data.filter((v) => {
                  console.log(v);
                  if (v.last_registers !== 0) return true;
                  if (v.createdAt > new Date().valueOf() / 1000 - 604800)
                    return true;
                  return false;
                })
              : data ?? []
          }
        />
      </section>
    </>
  );
};

export { Organizations };
