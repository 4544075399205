import adapter from '../api'

class UserService {
  constructor(){
    this.axios = adapter().request
  }

  async login({user, pass}) {
    return new Promise( async (resolve, reject) => {
      this.axios({
        method: 'post',
        url: 'auth/login',
        data: {
          user,
          pass
        }
      }, {
        400: 'Email y contraseña son requeridos',
        401: 'El email o contraseña son incorrectos',
      })
      .then(resolve)
      .catch(reject)
    })
  }

  async checkSession() {
    return new Promise( async (resolve, reject) => {
      this.axios({
        method: 'get',
        url: 'auth/login',
      })
      .then(resolve)
      .catch(reject)
    }) 
  }

}

export default UserService;