import React from "react";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

import { Dashboard } from "../pages/Dashboard/containers/Dashboard";
import { Organizations } from "../pages/Dashboard/containers/Organizations";
import { NotFound } from "../pages/NotFound";
import { Login } from "../pages/Login";

import useUser from "../hooks/useUser";
import { Layout } from "../pages/Dashboard/Layout";
import { Accounts } from "../pages/Dashboard/containers/Accounts";
import { Registers } from "../pages/Dashboard/containers/Registers";
import { OrganizationDetail } from "../pages/Dashboard/containers/OrganizationDetail";
import { ApiPage } from "../pages/Dashboard/containers/Api";
import { SubscriptionsPage } from "../pages/Dashboard/containers/Subscriptions";
import { UpdatePlansPage } from "../pages/Dashboard/containers/UpdatePlans";

const App = () => {
  const { logged } = useUser();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={<Navigate to={logged ? "dashboard" : "login"} />}
        />

        <Route
          path="/dashboard/"
          element={logged ? <Layout /> : <Navigate to="/login" />}
        >
          <Route index element={<Dashboard />} />
          <Route path="organizations" element={<Organizations />} />
          <Route path="organization-detail" element={<OrganizationDetail />} />
          <Route path="accounts" element={<Accounts />} />
          <Route path="registers" element={<Registers />} />
          <Route path="api" element={<ApiPage />} />
          <Route path="subscriptions" element={<SubscriptionsPage />} />
          <Route path="subscriptions/edit" element={<UpdatePlansPage />} />

          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>

        <Route
          path="/login"
          element={logged ? <Navigate to="/dashboard" /> : <Login />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
