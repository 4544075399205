import {useCallback, useState} from 'react'
import DashboardService from '../service/dashboard';

export default function useDashboard () {
  const [state, setState] = useState({loading: false, error: false, message: null, data: {}})
	const dashboardService = new DashboardService()

  const getResume = useCallback(() => {
    return new Promise((resolve, reject) => {
      setState({...state, loading: true, error: false })
      dashboardService.getResume()
        .then((response) => {
          setState({...state, loading: false, error: false, data: response})
          resolve({success: true})
        })
        .catch(err => {
          setState({...state, loading: false, error: true, message: err.message})
          reject({success: false})
        })
    })
  }, [setState, state]) 

  return {
    error: state.error,
    message: state.message,
    loading: state.loading,
    data: state.data,
    getResume,
    setError: (error, message) => {setState(prev => ({...prev, error, message}))},
    setLoading: loading => {setState(prev => ({...prev, loading}))}
  }
} 