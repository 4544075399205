export function resolvePath(obj, prop) {
  let parts = prop.split(/[.[\]'"]/).filter(p => p)
  
  if(parts.length === 1) return obj[parts[0]]

  if (Array.isArray(parts)) {
    let last = parts.pop(),
    l = parts.length,
    i = 1,
    current = parts[0];
    
    while((obj = obj[current]) && i < l) {
      current = parts[i];
      i++;
    }

    if(obj) return obj[last];
  }
  
}

export function resolveDestination(stops, tripType){
  const stopsWithDate = stops.filter(v => v.date && !isNaN(v.date) )

  if(tripType === 1 || tripType === 2){
    const time = stops[1].date?.valueOf() - stops[0].date?.valueOf()
    const dest = {time, place: stops[1].from} 
    return {dest, stopsWithDate} 
  }

  const middle = Math.floor(stopsWithDate.length / 2)
  const initial = {
    place: stopsWithDate[middle].from, 
    time: 0
  }

  const dest = stopsWithDate.reduce((acc, curr, i) => {
    if(i === 0) return initial
    if(i === stopsWithDate.length-1 || !curr.date || isNaN(curr.date)) return acc

    const prev = stopsWithDate[i-1].date?.valueOf()
    const currTime = curr.date?.valueOf() - prev
    if(currTime > acc.time){
      return {time: currTime, place: curr.from}
    }
    return acc
  }, initial)

  return {dest, stopsWithDate}
}

export function reviver(key, value) {
  const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|.\d{3}Z)/;
  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}

export function dateFormatter(date, dateRequired = true, timeRequired = true){
  if(!date) return ''
  let d = date

  if(typeof date === 'number'){
    const l = date.toString().length
    if(l < 13){
      d = new Date(l*1000)
    }else{
      d = new Date()
    }
  }

  if(typeof date === 'string'){
    d = new Date(date)
  }

  if(typeof date === 'object' && isNaN(date)){
    d = new Date('Invalid Date')
  }

  const formattedDate = d.toLocaleDateString()
  const formattedtime = d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})

  const formatted = []
  if(dateRequired) formatted.push(formattedDate)
  if(timeRequired) formatted.push(formattedtime)

  return formatted.join(' ')
}