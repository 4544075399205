import React from 'react';

import { Header } from "../components/Header";
import { Button } from '../components/Button'
import { Link } from 'react-router-dom';
import ErrorImage from '../assets/images/404.png';
import {Row, Col, Container} from 'react-bootstrap';
import './shared.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const NotFound = () => { 
  return (
    <>
      <Header/>
      <Container className='centered_section centered_section-404'>
        <Row className="error404__section">
          <Col>
            <img className="error404__image" src={ErrorImage} alt="404"/>
          </Col>
          <Col >
            <h2 className="error404__title">OOPS!<br/>PARECE QUE TE PERDISTE</h2>
            <p>La URL solicitada no fue encontrada</p>
            <br/>
            <Link to="">
              <Button filled={true} text="Volver al inicio" large={false}/>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export { NotFound };