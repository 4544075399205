import adapter from "../api";

class ApiResumeService {
  constructor() {
    this.axios = adapter().request;
  }

  async getApiResume(params) {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "requests/web",
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async getEndpointsUsage(params) {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "requests/web/endpoints",
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async getEndpointsLatency(params) {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "requests/web/endpoints_latency",
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  }
}

export default ApiResumeService;
