import { useEffect } from "react";
import { Button, Col, FormText, Row } from "react-bootstrap";
import "../styles.css";
import useSubscriptions from "../../../hooks/useSubscriptions";
import { Link } from "react-router-dom";

import { PieGraph } from "../components/PieGraph";
import { ContentTable } from "../components/ContentTable";
import {
  CheckCircleFill,
  CircleFill,
  Exclamation,
  HandIndexFill,
  Pause,
  PencilSquare,
  StopFill,
} from "react-bootstrap-icons";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#f93e3e"];

const STATUS = {
  authorized: <CheckCircleFill color="#00C49F" />,
  manual: <HandIndexFill color="#f93e3e" />,
  cancelled: <StopFill color="#f93e3e" />,
  pending: <Exclamation color="#FF8042" />,
  paused: <Pause color="#FFBB28" />,
  created: <CheckCircleFill color="#0088FE" />,
};

const SubscriptionsPage = () => {
  const { data, getPlans, getOrgPlans } = useSubscriptions();

  useEffect(() => {
    getPlans();
    getOrgPlans();
  }, []);

  return (
    <>
      <Col>
        <Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div>
                  <h3
                    style={{
                      display: "flex",
                      flexGrow: "999",
                      justifyContent: "space-between",
                    }}
                    className="content-table__title"
                  >
                    <span>Planes</span>
                    <span>
                      {" "}
                      Total por mes: $
                      {data?.plans?.reduce(
                        (acc, curr) =>
                          acc + (curr.orgs ?? 0) * (curr.price ?? 0),
                        0
                      )}
                    </span>
                    <span style={{ visibility: "hidden" }}>a</span>
                  </h3>
                </div>
                <Row>
                  <Col>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <PieGraph
                        data={
                          data?.plans
                            ? data?.plans.map(({ id, name, orgs }) => ({
                                name,
                                value: orgs,
                                color: COLORS[id],
                              }))
                            : []
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <Link to="/dashboard/subscriptions/edit">
                        <Button>
                          Editar planes <PencilSquare />
                        </Button>
                      </Link>
                    </div>
                    <ContentTable
                      columns={[
                        {
                          Header: "ID",
                          accessor: "id",
                        },
                        {
                          Header: "Nombre",
                          accessor: ({ name, id }) => {
                            return (
                              <span
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  backgroundColor: COLORS[id],
                                  padding: "0 1rem",
                                  borderRadius: "0.2rem",
                                }}
                              >
                                {name}
                              </span>
                            );
                          },
                        },
                        {
                          Header: "Empleados",
                          accessor: "employees",
                        },
                        {
                          Header: "Precio",
                          accessor: ({ price }) => {
                            return `$ ${price}`;
                          },
                        },
                        {
                          Header: "Empresas",
                          accessor: "orgs",
                        },
                        {
                          Header: "Total",
                          accessor: ({ price, orgs }) => {
                            return `$ ${price * (orgs ?? 0)}`;
                          },
                        },
                      ]}
                      data={data?.plans ?? []}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div>
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Empresas
                  </h3>
                </div>
                <Row>
                  <ContentTable
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                      },
                      {
                        Header: "Nombre",
                        accessor: "name",
                      },
                      {
                        Header: "Empleados",
                        accessor: ({ employees, maxEmployees }) => {
                          return (
                            <span>
                              {employees} /{" "}
                              {maxEmployees ? maxEmployees : "Infinitos"}
                            </span>
                          );
                        },
                      },
                      {
                        Header: "Capacidad",
                        sortType: (rowA, rowB, id, desc) => {
                          if (!rowA.original?.maxEmployees) {
                            if (!rowB.original?.maxEmployees) return 0;
                            return -1;
                          }

                          const rA = Math.floor(
                            100 -
                              ((rowA.original.maxEmployees -
                                rowA.original.employees) *
                                100) /
                                rowA.original.maxEmployees
                          );
                          const rB = Math.floor(
                            100 -
                              ((rowB.original.maxEmployees -
                                rowB.original.employees) *
                                100) /
                                rowB.original.maxEmployees
                          );
                          if (rA > rB) return 1;
                          if (rB > rA) return -1;
                          return 0;
                        },
                        accessor: ({ maxEmployees, employees }) => {
                          if (!maxEmployees) {
                            return (
                              <span
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  backgroundColor: `${COLORS[1]}c0`,
                                  padding: "0 0.3rem",
                                  borderRadius: "0.2rem",
                                }}
                              >
                                Sin limite
                              </span>
                            );
                          }
                          const r = Math.floor(
                            100 -
                              ((maxEmployees - employees) * 100) / maxEmployees
                          );
                          let color = COLORS[1];
                          if (r > 20) {
                            color = COLORS[0];
                          }
                          if (r > 40) {
                            color = COLORS[2];
                          }
                          if (r > 80) {
                            color = COLORS[3];
                          }
                          if (r > 90) {
                            color = COLORS[4];
                          }

                          return (
                            <span
                              style={{
                                color: "white",
                                fontWeight: "bold",
                                backgroundColor: `${color}c0`,
                                padding: "0 0.3rem",
                                borderRadius: "0.2rem",
                              }}
                            >
                              {r}%
                            </span>
                          );
                        },
                      },
                      {
                        Header: "Plan",
                        accessor: ({ plan, plan_id }) => {
                          return (
                            <span
                              style={{
                                color: "white",
                                fontWeight: "bold",
                                backgroundColor: COLORS[plan_id],
                                padding: "0 1rem",
                                borderRadius: "0.2rem",
                              }}
                            >
                              {plan}
                            </span>
                          );
                        },
                      },
                      {
                        Header: "Status",
                        accessor: ({ status }) => {
                          if (!STATUS[status]) {
                            return <CircleFill color="grey" />;
                          }
                          return STATUS[status];
                        },
                      },
                      {
                        Header: "Dueño",
                        accessor: "owner",
                      },
                      {
                        Header: "Payer Email",
                        accessor: "payerEmail",
                      },
                      // {
                      //   Header: "Acciones",
                      //   accessor: ({ id }) => {
                      //     return (
                      //       <Button size="sm">
                      //         <PencilSquare />
                      //       </Button>
                      //     );
                      //   },
                      // },
                    ]}
                    data={data?.orgs ?? []}
                  />
                </Row>
              </div>
            </Col>
          </Row>
        </Row>
      </Col>
    </>
  );
};

export { SubscriptionsPage };
