import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { BarChart } from "../components/BarChart";
import useResizeAware from "react-resize-aware";
import "../styles.css";
import { AreaChart } from "../components/AreaChart";
import useRegisters from "../../../hooks/useRegisters";

const Registers = () => {
  const { data, getRegistersHours, getTotalRegisters, getWeekdaysRegisters } =
    useRegisters();
  const [entrys, setEntrys] = useState([]);
  const [egresses, setEgresses] = useState([]);
  const [totalRegisters, setTotalRegisters] = useState([]);
  const [weekdaysRegistes, setWeekdaysRegisters] = useState([]);
  const [totalRegistersLength, setTotalRegistersLength] = useState(7);
  const [chunkSize, setChunkSize] = useState(7);
  const [resizeListener, sizes] = useResizeAware();
  const [smallResizeListener, smallSizes] = useResizeAware();

  useEffect(() => {
    getRegistersHours();
    getTotalRegisters();
    getWeekdaysRegisters();
  }, []);

  useEffect(() => {
    setEntrys(
      data?.hours?.entrys?.map((v, i) => ({
        name: `${i}hs`,
        value: Math.trunc(v / 7),
      }))
    );
  }, [data?.hours?.entrys]);

  useEffect(() => {
    setEgresses(
      data?.hours?.egresses?.map((v, i) => ({
        name: `${i}hs`,
        value: Math.trunc(v / 7),
      }))
    );
  }, [data?.hours?.egresses]);

  useEffect(() => {
    if (!data?.weekdays) return setWeekdaysRegisters([]);
    const weekdays = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];

    setWeekdaysRegisters(
      weekdays.map((d, i) => ({ name: d, value: data?.weekdays[i] }))
    );
  }, [data?.weekdays]);

  useEffect(() => {
    console.log(chunkSize);
    if (!data?.total || data?.total?.length <= 0) return setTotalRegisters([]);

    const formatted =
      data?.total
        ?.slice(0, totalRegistersLength)
        ?.map((v) => ({
          name: new Date(v.date).toLocaleDateString("es-AR", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }),
          date: v.date,
          value: v.registers,
        }))
        ?.reverse() ?? [];
    for (let i = 0; i < formatted.length; i += chunkSize) {
      const chunk = formatted.slice(i, i + chunkSize);
      const avg =
        chunk.reduce((a, b) => {
          return a + b.value;
        }, 0) / chunk.length;
      console.log(i, chunk, avg);
      formatted[i].average = avg;
    }

    setTotalRegisters(formatted);
  }, [data?.total, totalRegistersLength, chunkSize]);

  return (
    <>
      <Col>
        <Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div style={{ position: "relative" }}>{resizeListener}</div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Entradas por horario (por dia)
                  </h3>
                  <Form.Select
                    value={7}
                    disabled={true}
                    style={{ width: "15rem" }}
                    aria-label="Default select"
                  >
                    <option value="7">7 Dias</option>
                  </Form.Select>
                </div>
                <BarChart
                  name="Entradas"
                  width={sizes.width - 50}
                  height={400}
                  data={entrys || []}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div style={{ position: "relative" }}>{resizeListener}</div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Salidas por horario (por dia)
                  </h3>
                  <Form.Select
                    value={7}
                    disabled={true}
                    style={{ width: "15rem" }}
                    aria-label="Default select"
                  >
                    <option value="7">7 Dias</option>
                  </Form.Select>
                </div>
                <BarChart
                  height={400}
                  name="Salidas"
                  width={sizes.width - 50}
                  data={egresses || []}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div style={{ position: "relative" }}>{resizeListener}</div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Registros por dia
                  </h3>
                  <Form.Group className="mb-3">
                    <Form.Label>Promedio por dias:</Form.Label>
                    <Form.Select
                      value={chunkSize}
                      onChange={(v) => setChunkSize(parseInt(v.target.value))}
                      style={{ width: "15rem", marginRight: "2rem" }}
                      aria-label="Default select"
                    >
                      <option value={3}>3 Dias</option>
                      <option value={7}>7 Dias</option>
                      <option value={15}>15 Dias</option>
                      <option value={30}>30 Dias</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Cantidad registros:</Form.Label>
                    <Form.Select
                      value={totalRegistersLength}
                      onChange={(v) => setTotalRegistersLength(v.target.value)}
                      style={{ width: "15rem" }}
                      aria-label="Default select"
                    >
                      <option value="7">7 Dias</option>
                      <option value="30">30 Dias</option>
                      <option value="90">3 meses</option>
                      <option value="180">6 meses</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <AreaChart
                  name="Registros"
                  width={sizes.width - 50}
                  height={400}
                  data={totalRegisters || []}
                  showWeekdays={true}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div style={{ position: "relative" }}>
                  {smallResizeListener}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Registros por dia de la semana
                  </h3>
                  <Form.Select
                    value={7}
                    disabled={true}
                    style={{ width: "15rem" }}
                    aria-label="Default select"
                  >
                    <option value="7">7 Dias</option>
                  </Form.Select>
                </div>
                <BarChart
                  name="Entradas"
                  width={smallSizes.width}
                  data={weekdaysRegistes || []}
                />
              </div>
            </Col>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div style={{ position: "relative" }}>
                  {smallResizeListener}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Espacio reservado para el futuro
                  </h3>
                  <Form.Select
                    value={7}
                    disabled={true}
                    style={{ width: "15rem" }}
                    aria-label="Default select"
                  >
                    <option value="7">7 Dias</option>
                  </Form.Select>
                </div>
                {/* <BarChart
                  name="Entradas"
                  width={smallSizes.width}
                  data={entrys || []}
                /> */}
              </div>
            </Col>
          </Row>
        </Row>
      </Col>
    </>
  );
};

export { Registers };
