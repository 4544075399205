import React from "react";
import SidebarItem from "./components/SidebarItem";
import headerLogo from "../../../../assets/images/logo_blanco.png";
import {
  Grid1x2Fill,
  BriefcaseFill,
  Clipboard,
  UpcScan,
  PeopleFill,
  GearFill,
  BoxArrowRight,
  Clock,
  ExclamationTriangleFill,
  Building,
  Person,
} from "react-bootstrap-icons";

const Sidebar = ({
  handleToggleSidebar,
  sidebar,
  handleLogout: superHandleLogout,
  children,
}) => {
  const handleLogout = (e) => {
    e.preventDefault();
    superHandleLogout();
  };

  return (
    <div
      id="sidebar"
      className={`${sidebar ? "" : "hidden"} dashboard-template__sidebar`}
    >
      <div>
        <div className="dashboard-template__sidebar-header">
          <div
            className={`${
              sidebar ? "dashboard-template__ham-active" : ""
            } dashboard-template__ham-container`}
            onClick={handleToggleSidebar}
          >
            <div className="dashboard-template__ham-bar1"></div>
            <div className="dashboard-template__ham-bar2"></div>
            <div className="dashboard-template__ham-bar3"></div>
          </div>
          <img
            src={headerLogo}
            className={`${sidebar ? "" : "hidden"} dashboard-template__image`}
            alt="logo"
          />
        </div>
        <div className="dashboard-template__sidebar-items">
          {children}
          {/* <SidebarItem opened={sidebar} active={location} icon={<BriefcaseFill size={23}/>} to="dashboard/employees" text="Empleados"/>
          <SidebarItem opened={sidebar} active={location} icon={<Clipboard size={23}/>} to="dashboard/registers" text="Registros"/> 
          <SidebarItem opened={sidebar} active={location} icon={<ExclamationTriangleFill size={23}/>} to="dashboard/anomalies" text="Anomalias"/>
          <SidebarItem opened={sidebar} active={location} icon={<Clock size={23}/>} to="dashboard/shifts-asignment" text="Turnos"/>
          <SidebarItem opened={sidebar} active={location} icon={<UpcScan size={23}/>} to="dashboard/qr" text="QR"/>
          <SidebarItem opened={sidebar} active={location} icon={<PeopleFill size={23}/>} to="dashboard/admins" text="Administradores"/>
          <SidebarItem opened={sidebar} active={location} icon={<GearFill size={23}/>} to="dashboard/settings" text="Configuracion"/> */}
        </div>
      </div>

      <div className="dashboard-template__sidebar-footer">
        <SidebarItem
          onClick={handleLogout}
          opened={sidebar}
          icon={<BoxArrowRight size={23} />}
          text="Cerrar Sesion"
        />
        <div
          className={
            sidebar
              ? "dashboard-template__footer-text-visible"
              : "dashboard-template__footer-text-hidden"
          }
        >
          <hr className="dashboard-template__footer-text" />
          <p className="dashboard-template__footer-text">© 2022 Praesenti</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
