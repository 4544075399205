import React, { useState } from "react";

import loginImage from '../assets/images/27.png';
import { Button } from '../components/Button'
import { InputText } from '../components/InputText';
import { Row, Col} from 'react-bootstrap';
import './shared.css';

import { useNavigate } from 'react-router-dom';
import useUser from '../hooks/useUser'

const Login = () => { 
	const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
	let navigate = useNavigate();
	const {loading, error, message, login} = useUser()

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(username && password && !loading){
			login({ user: username, pass: password })
        .then(() => navigate('/dashboard'))
		}
	}

	return (
		<section className='centered_section'>
			
			<Row className='login__container'>
			
				<Col sm={6} className='login__image-container'>
					<img alt="Imagen de inicio de sesion" src={loginImage}/>
				</Col>
				<Col className='login__form-container'>
					<div>
						<h2>Hola! Ingresa tu mail y contraseña</h2>
						<form onSubmit={handleSubmit}>
							<InputText hasError={error} disabled={loading} onChange={(e) => setUsername(e.target.value)} value={username} name="email" label="Email"/>
							<InputText hasError={error} disabled={loading} onChange={(e) => setPassword(e.target.value)} isPassword={true} value={password} name="password" label="Contraseña"/>

							<div className="login__form-button">
									<Button loading={loading} filled={true} text="Ingresar" large={true}/>
							</div>

							<div style={{visibility: error ? 'visible' :'hidden'}} className="login__form-error">{message}</div>
						</form>
					</div>
				</Col>
			</Row>
		</section>
	);
}

export { Login };