import './styles.css'

const DashboardCounter = ({title, counter, children}) => {

  return (
    <div className="dashboard__counter">
      {children}
      <div className="dashboard__counters-title">
        <h6 style={{fontWeight: 400, color: 'grey'}}>{title}</h6>
        <h3 style={{fontWeight: 800, margin: "0 5px"}}>{counter}</h3>
      </div>
    </div>
  )
}

export { DashboardCounter }