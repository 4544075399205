import adapter from "../api";

class OrganizationsService {
  constructor() {
    this.axios = adapter().request;
  }

  async getResume() {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "organizations",
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async getDetail(id) {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: `organizations/${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  }
}

export default OrganizationsService;
