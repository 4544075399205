import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { LineChart } from "../components/LineChart";
import useAccounts from "../../../hooks/useAccounts";
import useResizeAware from "react-resize-aware";
import "../styles.css";
import { AreaChart } from "../components/AreaChart";

const Accounts = () => {
  const { data, getAccountsCreations } = useAccounts();
  const [accountCreations, setAccountCreations] = useState([]);
  const [totalAcounts, setTotalAcounts] = useState([]);
  const [newChartLength, setNewChartLength] = useState(7);
  const [accountsChartLength, setAccountsChartLength] = useState(7);
  const [resizeListener, sizes] = useResizeAware();

  useEffect(() => {
    getAccountsCreations();
  }, []);

  useEffect(() => {
    const tempNewAccounts = [];
    const TwentyFourHours = 1000 * 60 * 60 * 24;
    const now = new Date();
    const today = new Date(
      `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`
    );

    const accountsCopy = JSON.parse(JSON.stringify(data));

    for (let i = 0; i < newChartLength; i++) {
      const yesterday = new Date(today.valueOf() - TwentyFourHours * i);
      let accountsCreated = 0;

      for (let j = 0; j < accountsCopy.length; j++) {
        if (!accountsCopy[j] || accountsCopy[j] === null) continue;
        const creationDate = accountsCopy[j].creation_date;

        if (creationDate >= yesterday.valueOf() / 1000) {
          accountsCreated++;
          accountsCopy[j] = null;
        }
      }

      tempNewAccounts.push({
        name: `${yesterday.getDate()}/${yesterday.getMonth() + 1}`,
        value: accountsCreated,
      });
    }

    setAccountCreations(tempNewAccounts.reverse());
  }, [data, newChartLength]);

  useEffect(() => {
    const tempTotalAccounts = [];
    const TwentyFourHours = 1000 * 60 * 60 * 24;
    const now = new Date();
    const today = new Date(
      `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`
    );

    for (let i = 0; i < accountsChartLength; i++) {
      const yesterday = new Date(today.valueOf() - TwentyFourHours * i);

      tempTotalAccounts.push({
        name: `${yesterday.getDate()}/${yesterday.getMonth() + 1}`,
        value:
          data && data.length > 0
            ? data?.filter((v) => v.creation_date <= yesterday.valueOf() / 1000)
                ?.length
            : 0,
      });
    }

    setTotalAcounts(tempTotalAccounts.reverse());
  }, [data, newChartLength, accountsChartLength]);

  return (
    <>
      <Col>
        <Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div style={{ position: "relative" }}>{resizeListener}</div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Creacion de cuentas
                  </h3>
                  <Form.Select
                    value={newChartLength}
                    onChange={(v) => setNewChartLength(v.target.value)}
                    style={{ width: "15rem" }}
                    aria-label="Default select"
                  >
                    <option value="7">7 Dias</option>
                    <option value="30">30 Dias</option>
                    <option value="90">3 meses</option>
                  </Form.Select>
                </div>
                <LineChart
                  name="Nuevas cuentas"
                  width={sizes.width - 50}
                  data={accountCreations}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div style={{ position: "relative" }}>{resizeListener}</div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Cuentas totales
                  </h3>
                  <Form.Select
                    value={accountsChartLength}
                    onChange={(v) => setAccountsChartLength(v.target.value)}
                    style={{ width: "15rem" }}
                    aria-label="Default select"
                  >
                    <option value="7">7 Dias</option>
                    <option value="30">30 Dias</option>
                    <option value="90">3 meses</option>
                  </Form.Select>
                </div>
                <AreaChart
                  name="Cuentas totales"
                  width={sizes.width - 50}
                  data={totalAcounts}
                />
              </div>
            </Col>
          </Row>
        </Row>
      </Col>
    </>
  );
};

export { Accounts };
