import { useCallback, useState } from "react";
import ApiResumeService from "../service/apiResume";

export default function useApiResume() {
  const [state, setState] = useState({
    loading: false,
    error: false,
    message: null,
    data: {},
  });
  const apiService = new ApiResumeService();

  const getApiResume = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        setState({ ...state, loading: true, error: false });
        apiService
          .getApiResume(params)
          .then((response) => {
            setState((prev) => ({
              ...prev,
              loading: false,
              error: false,
              data: { ...prev.data, resume: response },
            }));
            resolve({ success: true });
          })
          .catch((err) => {
            setState({
              ...state,
              loading: false,
              error: true,
              message: err.message,
            });
            reject({ success: false });
          });
      });
    },
    [setState, state]
  );

  const getEndpointsUsage = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        setState({ ...state, loading: true, error: false });
        apiService
          .getEndpointsUsage(params)
          .then((response) => {
            setState((prev) => ({
              ...prev,
              loading: false,
              error: false,
              data: { ...prev.data, usage: response },
            }));
            resolve({ success: true });
          })
          .catch((err) => {
            setState({
              ...state,
              loading: false,
              error: true,
              message: err.message,
            });
            reject({ success: false });
          });
      });
    },
    [setState, state]
  );

  const getEndpointsLatency = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        setState({ ...state, loading: true, error: false });
        apiService
          .getEndpointsLatency(params)
          .then((response) => {
            setState((prev) => ({
              ...prev,
              loading: false,
              error: false,
              data: { ...prev.data, latency: response },
            }));
            resolve({ success: true });
          })
          .catch((err) => {
            setState({
              ...state,
              loading: false,
              error: true,
              message: err.message,
            });
            reject({ success: false });
          });
      });
    },
    [setState, state]
  );

  return {
    error: state.error,
    message: state.message,
    loading: state.loading,
    data: state.data,
    getApiResume,
    getEndpointsUsage,
    getEndpointsLatency,
    setError: (error, message) => {
      setState((prev) => ({ ...prev, error, message }));
    },
    setLoading: (loading) => {
      setState((prev) => ({ ...prev, loading }));
    },
  };
}
