import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Eye, EyeSlash, CheckCircle, XCircle } from "react-bootstrap-icons";
import "./styles.css";

function filterObject(obj, callback) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => callback(val, key))
  );
}

const ValidationItem = ({ validated, children }) => {
  return (
    <span
      className="password-validation-popover__item"
      style={{
        color: validated ? "green" : "red",
      }}
    >
      {" "}
      {validated ? (
        <CheckCircle style={{ marginRight: "5px" }} />
      ) : (
        <XCircle style={{ marginRight: "5px" }} />
      )}{" "}
      {children}
    </span>
  );
};

const InputText = (props) => {
  const [visibility, setVisibility] = useState(false);
  const [securityValidation, setSecuritiValidation] = useState({});
  const classes = props.hasError ? "input__text-input-error " : "";

  function validatePassword(e) {
    const value = e.target.value;

    const lower = /[a-z]/g;
    const upper = /[A-Z]/g;
    const numbers = /[0-9]/g;

    if (!value) {
      setSecuritiValidation({});
      return props.onChange(e, false);
    }

    const validation = {
      pwLength: value.length >= 8,
      numbers: value.match(numbers)?.length > 0,
      lower: value.match(lower)?.length > 0,
      upper: value.match(upper)?.length > 0,
    };
    setSecuritiValidation(validation);

    if (props.onChange)
      props.onChange(
        e,
        Object.keys(filterObject(validation, (v) => v)).length === 4
      );
  }

  let inputStyles = props.isPassword
    ? { paddingRight: "2.5em", width: "100%" }
    : { width: "100%" };

  inputStyles = { ...inputStyles, ...props.inputStyle };

  return (
    <div style={props.style ?? {}} className="input__text-input-container">
      {props.label != null ? (
        <label className="input__text-label" htmlFor={props.name}>
          {props.label}
        </label>
      ) : null}
      <span>
        <input
          style={inputStyles}
          className={"input__text-input " + classes}
          type={
            props.isPassword
              ? visibility
                ? "text"
                : "password"
              : props.type ?? "text"
          }
          id={props.name}
          name={props.name}
          placeholder={props.placeholder ?? null}
          onChange={(e) => {
            if (props.isPassword) validatePassword(e);
            else props.onChange(e);
          }}
          value={props.value}
          disabled={props.disabled}
        />
        {props.isPassword ? (
          <i
            onClick={() => {
              setVisibility(!visibility);
            }}
            id="togglePassword"
          >
            {visibility ? <Eye size={20} /> : <EyeSlash size={20} />}
          </i>
        ) : null}
      </span>

      {props.passwordValidation ? (
        <div style={{}} className="input__password-validation-container">
          <OverlayTrigger
            key="passwordValidator"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Validacion de contraseña
                </Popover.Header>
                <Popover.Body>
                  <div className="input__password-validation-popover">
                    <ValidationItem validated={securityValidation.pwLength}>
                      8 caracteres
                    </ValidationItem>
                    <ValidationItem validated={securityValidation.lower}>
                      Al menos 1 minuscula
                    </ValidationItem>
                    <ValidationItem validated={securityValidation.upper}>
                      Al menos 1 mayuscula
                    </ValidationItem>
                    <ValidationItem validated={securityValidation.numbers}>
                      Al menos 1 numero
                    </ValidationItem>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <div
              style={{
                width: "50px",
                height: "10px",
                backgroundColor:
                  Object.keys(securityValidation).length > 0
                    ? Object.keys(filterObject(securityValidation, (v) => v))
                        .length === 4
                      ? "green"
                      : "red"
                    : "rgb(49 49 49 / 24%)",
              }}
            ></div>
          </OverlayTrigger>
        </div>
      ) : null}
    </div>
  );
};

export { InputText };
