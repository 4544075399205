import { useEffect, useState } from "react";
import useOrganizations from "../../../hooks/useOrganizations";
import { ContentTable } from "../components/ContentTable";
import { Badge, Col, Form, Row } from "react-bootstrap";
import {
  XCircleFill,
  PatchCheckFill,
  Person,
  Clipboard,
  PersonCheck,
  Clock,
  Building,
  PersonFill,
} from "react-bootstrap-icons";

import "../styles.css";
import Select from "../components/Select";
import { DashboardCounter } from "../components/DashboardCounter";
import { AreaChart } from "../components/AreaChart";
import useResizeAware from "react-resize-aware";
import { Line } from "recharts";

const OrganizationDetail = () => {
  const {
    getResume,
    data: allOrgs,
    loading,
    getDetail,
    detail,
  } = useOrganizations();
  const [hideInactive, setHideInactive] = useState(true);
  const [selected, setSelected] = useState(null);
  const [resizeListener, sizes] = useResizeAware();
  const [totalRegistersLength, setTotalRegistersLength] = useState(7);
  const [totalRegisters, setTotalRegisters] = useState([]);

  useEffect(getResume, []);

  useEffect(() => {
    if (!selected) return;
    getDetail(selected);
  }, [selected]);

  useEffect(() => {
    if (!detail?.totalRegisters || detail?.totalRegisters?.length <= 0)
      return setTotalRegisters([]);
    const formatted =
      detail?.totalRegisters
        ?.slice(0, totalRegistersLength)
        ?.map((v) => {
          return {
            name: new Date(v.date).toLocaleDateString("es-AR", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }),
            date: v.date,
            value: v.registers,
          };
        })
        ?.reverse() ?? [];

    const chunkSize = 7;
    for (let i = 0; i < formatted.length; i += chunkSize) {
      const chunk = formatted.slice(i, i + chunkSize);
      const avg =
        chunk.reduce((a, b) => {
          return a + b.value;
        }, 0) / chunk.length;
      console.log(i, chunk, avg);
      formatted[i].average = avg;
    }
    setTotalRegisters(formatted);
  }, [detail?.totalRegisters, totalRegistersLength]);

  return (
    <>
      <section className="dashboard-section__container">
        <div className="content-table__header">
          <h2 className="content-table__title">Detalle de empresa</h2>
        </div>

        <div>
          <label>
            <input
              type="checkbox"
              checked={hideInactive}
              onChange={() => {
                setHideInactive((p) => !p);
              }}
            />
            Ocultar inactivas
          </label>{" "}
        </div>

        <div>
          <Select
            label="Seleccionar empresa"
            value={selected}
            setValue={setSelected}
            options={allOrgs
              .filter((v) => {
                if (!hideInactive) return true;
                if (v.last_registers !== 0) return true;
                if (v.createdAt > new Date().valueOf() / 1000 - 604800)
                  return true;
                return false;
              })
              .map((v) => ({ name: v.name, id: v.id }))}
          />
        </div>

        <hr />

        {detail && !loading && (
          <>
            <div className="dashboard__indicators-section">
              <DashboardCounter title="Registros" counter={detail.registers}>
                <Clipboard size={40} />
              </DashboardCounter>
              <DashboardCounter title="Empleados" counter={detail.employees}>
                <Person size={40} />
              </DashboardCounter>
              <DashboardCounter
                title="Empleados Registrados"
                counter={detail.registered_employees}
              >
                <PersonCheck size={40} />
              </DashboardCounter>
              <DashboardCounter title="Administradores" counter={detail.users}>
                <PersonFill size={40} />
              </DashboardCounter>
            </div>
            <Row>
              <Col className="dashboard__graph-col">
                <div className="dashboard__card">
                  <div style={{ position: "relative" }}>{resizeListener}</div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3
                      style={{ flexGrow: "999" }}
                      className="content-table__title"
                    >
                      Registros por dia
                    </h3>
                    <Form.Select
                      value={totalRegistersLength}
                      onChange={(v) => setTotalRegistersLength(v.target.value)}
                      style={{ width: "15rem" }}
                      aria-label="Default select"
                    >
                      <option value="7">7 Dias</option>
                      <option value="30">30 Dias</option>
                      <option value="90">3 meses</option>
                      <option value="180">6 meses</option>
                    </Form.Select>
                  </div>
                  <AreaChart
                    name="Registros"
                    width={sizes.width - 50}
                    height={400}
                    data={totalRegisters || []}
                    showWeekdays
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </section>
    </>
  );
};

export { OrganizationDetail };
