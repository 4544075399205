import {Link} from 'react-router-dom';

import './styles.css'

const SidebarItem = (props) => {

  if(props.to){
    return (
      <Link to={`/${props.to}`} className={` ${(props.active === `/${props.to}`) ? 'sidebar__item-active' : ''} sidebar__item`}>
        <p className="sidebar__item-icon">{props.icon}</p>
        <p className={`${props.opened ? '' : 'sidebar__item-title-hidden'} sidebar__item-title`}>{props.text}</p>
      </Link>
    )
  }else{
    return(
      <span onClick={props.onClick} className="sidebar__item sidebar__item-button">
        <p className="sidebar__item-icon">{props.icon}</p>
        <p className={`${props.opened ? '' : 'sidebar__item-title-hidden'} sidebar__item-title`}>{props.text}</p>
      </span>
    )
  }

}

export default SidebarItem;