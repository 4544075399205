import React, {useEffect, useState} from 'react'
import UserService from '../service/user';

const Context = React.createContext({})

export function UserContextProvider ({children}) {
  const token = window.localStorage.getItem('token')
  const [session, setSession] = useState({token})
	const userService = new UserService()

  useEffect(() => {
    if(!session.token) return window.localStorage.removeItem('token')
    window.localStorage.setItem('token', session.token)
  }, [session.token])

  useEffect(() => {
    if(session.token && !session.data){
      const token = session.token
      userService.checkSession({token})
      .then(response => {
        setSession(prev => ({...prev, data: response?.user}))
      })
      .catch(err => {
        setSession({})
      })
    }
  }, [])

  return <Context.Provider value={{
    setSession,
    session
  }}>
    {children}
  </Context.Provider>
}

export default Context