import adapter from "../api";

class AccountsService {
  constructor() {
    this.axios = adapter().request;
  }

  async getAccountsCreations() {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "accounts",
      })
        .then(resolve)
        .catch(reject);
    });
  }
}

export default AccountsService;
