import { BarChart as PrBarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";

function BarChart({ data, width, height, name }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {data.length > 0 ? (
        <PrBarChart
          width={width || 500}
          height={height || 300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis tickLine={false} axisLine={false} dataKey="name" />
          <YAxis
            padding={{ bottom: 20 }}
            interval="preserveEnd"
            tickLine={false}
            axisLine={false}
            domain={[
              (dataMin) => dataMin - dataMin / 10,
              (dataMax) => dataMax + dataMax / 10,
            ]}
          />
          <Tooltip cursor={false} />
          {/* <Legend /> */}
          <Bar dataKey="value" fill="#4C399F" name={name ?? "Valor"} />
        </PrBarChart>
      ) : (
        <h6 style={{ marginTop: "3vh" }}>No hay datos disponibles</h6>
      )}
    </div>
  );
}

export { BarChart };
