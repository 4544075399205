import adapter from "../api";

class ApiResumeService {
  constructor() {
    this.axios = adapter().request;
  }

  async getPlans(params) {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "subscriptions/plans",
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async getOrgPlans(params) {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "subscriptions/orgs",
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async updatePlans(data) {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "put",
        url: "subscriptions/plans",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
}

export default ApiResumeService;
