import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ArrowClockwise } from "react-bootstrap-icons";
import useResizeAware from "react-resize-aware";
import "../styles.css";
import useApiResume from "../../../hooks/useApiResume";
import {
  Area,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  Brush,
  Treemap,
  BarChart as PrBarChart,
  Bar,
} from "recharts";
import { BarChart } from "../components/BarChart";

const COLORS = {
  GET: "#61affe",
  POST: "#49cc90",
  DELETE: "#f93e3e",
  PUT: "#fca130",
};

const ApiPage = () => {
  const {
    loading,
    data,
    getApiResume,
    getEndpointsUsage,
    getEndpointsLatency,
  } = useApiResume();
  const [smallResizeListener, smallSizes] = useResizeAware();
  const [resizeListener, sizes] = useResizeAware();
  const [resume, setResume] = useState([]);
  const [resumeLength, setResumeLength] = useState(60);
  const [interval, setInterval] = useState(60);
  const [ignoreQR, setIgnoreQR] = useState(false);

  function reloadResume() {
    const now = new Date();
    const from = new Date(now.valueOf() - resumeLength * 60000);
    console.log(ignoreQR);
    getApiResume({
      from,
      to: now,
      interval,
      exclude: ignoreQR ? "/api/qr/generate" : "",
    });
    getEndpointsUsage({
      from,
      to: now,
      exclude: ignoreQR ? "/api/qr/generate" : "",
    });
    getEndpointsLatency({
      from,
      to: now,
      exclude: ignoreQR ? "/api/qr/generate" : "",
    });
  }

  useEffect(() => {
    reloadResume();
  }, []);

  useEffect(() => {
    if (!data?.resume || data?.resume?.length <= 0) return setResume([]);

    const formatted =
      data?.resume?.map((v) => ({
        name:
          interval > 7 * 24 * 60
            ? new Date(v.date).toLocaleDateString("es-AR", {
                month: "2-digit",
                year: "2-digit",
                day: "2-digit",
              })
            : new Date(v.date).toLocaleTimeString("es-AR", {}),
        date: v.date,
        average: v.resume.delay.average,
        range: [v.resume.delay.min, v.resume.delay.max],
        get: v.resume.methods.get,
        post: v.resume.methods.post,
        put: v.resume.methods.put,
        delete: v.resume.methods.delete,
        errors: v.resume.errors,
        success: v.resume.success,
      })) ?? [];

    setResume(formatted);
  }, [data?.resume]);

  return (
    <>
      <Col>
        <Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div
                className="dashboard__card"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Form.Group
                  style={{ marginRight: "2rem" }}
                  controlId="formBasicCheckbox"
                >
                  <Form.Check
                    onChange={(v) => setIgnoreQR(!ignoreQR)}
                    value={ignoreQR}
                    type="checkbox"
                    label="Ignorar endpoints de QR"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Intervalo:</Form.Label>
                  <Form.Select
                    value={interval}
                    onChange={(v) => setInterval(parseInt(v.target.value))}
                    style={{ width: "15rem", marginRight: "2rem" }}
                    aria-label="Default select"
                  >
                    <option value={60}>1 Minuto</option>
                    <option value={5 * 60}>5 Minutos</option>
                    <option value={30 * 60}>30 Minutos</option>
                    <option value={60 * 60}>1 Hora</option>
                    <option value={24 * 60 * 60}>1 Dia</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Cantidad registros:</Form.Label>
                  <Form.Select
                    value={resumeLength}
                    onChange={(v) => {
                      setResumeLength(parseInt(v.target.value));
                      switch (parseInt(v.target.value)) {
                        case 60:
                          setInterval(60);
                          break;
                        case 12 * 60:
                          setInterval(30 * 60);
                          break;
                        case 24 * 60:
                          setInterval(60 * 60);
                          break;
                        case 7 * 24 * 60:
                        case 30 * 24 * 60:
                          setInterval(24 * 60 * 60);
                          break;
                        default:
                          break;
                      }
                    }}
                    style={{ width: "15rem" }}
                    aria-label="Default select"
                  >
                    <option value={60}>1 Hora</option>
                    <option value={12 * 60}>12 Horas</option>
                    <option value={24 * 60}>1 Dia</option>
                    <option value={7 * 24 * 60}>7 Dias</option>
                    <option value={30 * 24 * 60}>30 Dias</option>
                  </Form.Select>
                </Form.Group>
                <Button
                  disabled={loading}
                  style={{ marginLeft: "1rem", marginTop: "1rem" }}
                  onClick={reloadResume}
                >
                  <ArrowClockwise />
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div style={{ position: "relative" }}>{resizeListener}</div>
                <div>
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Success / Errors
                  </h3>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {resume.length > 0 ? (
                    <ComposedChart
                      syncId={"charts"}
                      width={sizes.width - 50}
                      height={400}
                      data={resume}
                      margin={{
                        top: 25,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis tickLine={false} axisLine={false} dataKey="name" />

                      <YAxis
                        padding={{ bottom: 20 }}
                        interval="preserveEnd"
                        tickLine={false}
                        axisLine={false}
                        domain={[
                          (dataMin) => dataMin - dataMin / 10,
                          (dataMax) => dataMax + dataMax / 10,
                        ]}
                      />
                      <Tooltip cursor={false} />
                      <Area
                        type="monotone"
                        dataKey="success"
                        stroke="#49cc90"
                        fill="#49cc90E0"
                        name="Success"
                      />
                      <Line
                        connectNulls
                        strokeWidth={1.5}
                        legendType="plainline"
                        type="monotone"
                        dataKey="errors"
                        stroke="#f93e3e"
                        name={"Errors"}
                        dot={null}
                      />
                      <Brush dataKey="name" height={30} stroke="#8884d8" />
                      <Legend />
                    </ComposedChart>
                  ) : (
                    <h6 style={{ marginTop: "3vh" }}>
                      No hay datos disponibles
                    </h6>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div style={{ position: "relative" }}>
                  {smallResizeListener}
                </div>
                <div>
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    API Latency
                  </h3>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {resume.length > 0 ? (
                    <ComposedChart
                      syncId={"charts"}
                      width={smallSizes.width - 50}
                      height={400}
                      data={resume}
                      margin={{
                        top: 25,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis tickLine={false} axisLine={false} dataKey="name" />

                      <YAxis
                        padding={{ bottom: 20 }}
                        interval="preserveEnd"
                        tickLine={false}
                        axisLine={false}
                        domain={[
                          (dataMin) => dataMin - dataMin / 10,
                          (dataMax) => dataMax + dataMax / 10,
                        ]}
                      />
                      <Tooltip cursor={false} />
                      <Area
                        type="monotone"
                        dataKey="range"
                        stroke="#4C399F"
                        fill="#4C399FE0"
                        name="Latency (ms)"
                      />
                      <Legend />
                      <Line
                        connectNulls
                        strokeWidth={1.5}
                        legendType="plainline"
                        type="monotone"
                        dataKey="average"
                        stroke="#2d384b"
                        name={"Promedio"}
                        dot={null}
                      />
                    </ComposedChart>
                  ) : (
                    <h6 style={{ marginTop: "3vh" }}>
                      No hay datos disponibles
                    </h6>
                  )}
                </div>
              </div>
            </Col>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <div>
                  <h3
                    style={{ flexGrow: "999" }}
                    className="content-table__title"
                  >
                    Methods
                  </h3>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {resume.length > 0 ? (
                    <ComposedChart
                      syncId={"charts"}
                      width={smallSizes.width - 50}
                      height={400}
                      data={resume}
                      margin={{
                        top: 25,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis tickLine={false} axisLine={false} dataKey="name" />

                      <YAxis
                        padding={{ bottom: 20 }}
                        interval="preserveEnd"
                        tickLine={false}
                        axisLine={false}
                        domain={[
                          (dataMin) => dataMin - dataMin / 10,
                          (dataMax) => dataMax + dataMax / 10,
                        ]}
                      />
                      <Legend />

                      <Tooltip cursor={false} />
                      <Line
                        connectNulls
                        strokeWidth={1.5}
                        legendType="plainline"
                        type="monotone"
                        dataKey="post"
                        stroke={COLORS["POST"]}
                        name={"POST"}
                        dot={null}
                      />
                      <Line
                        connectNulls
                        strokeWidth={1.5}
                        legendType="plainline"
                        type="monotone"
                        dataKey="put"
                        stroke={COLORS["PUT"]}
                        name={"PUT"}
                        dot={null}
                      />
                      <Line
                        connectNulls
                        strokeWidth={1.5}
                        legendType="plainline"
                        type="monotone"
                        dataKey="get"
                        stroke={COLORS["GET"]}
                        name={"GET"}
                        dot={null}
                      />
                      <Line
                        connectNulls
                        strokeWidth={1.5}
                        legendType="plainline"
                        type="monotone"
                        dataKey="delete"
                        stroke={COLORS["DELETE"]}
                        name={"DELETE"}
                        dot={null}
                      />
                    </ComposedChart>
                  ) : (
                    <h6 style={{ marginTop: "3vh" }}>
                      No hay datos disponibles
                    </h6>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <h3 className="content-table__title">Methods usage</h3>
                <RadarChart
                  width={smallSizes.width - 50}
                  height={400}
                  cx="50%"
                  cy="50%"
                  outerRadius="80%"
                  data={
                    data?.usage?.methods
                      ? Object.keys(data?.usage?.methods)?.map((v) => ({
                          name: v,
                          value: data.usage.methods[v],
                        }))
                      : []
                  }
                >
                  <Legend />

                  <PolarGrid />
                  <PolarAngleAxis dataKey="name" />
                  <PolarRadiusAxis />
                  <Radar
                    name="Methods"
                    dataKey="value"
                    stroke="#8884d8"
                    fill="#8884d8"
                    fillOpacity={0.6}
                  />
                </RadarChart>
              </div>
            </Col>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <h3 className="content-table__title">StatusCodes</h3>
                <BarChart
                  name="Status Codes"
                  width={smallSizes.width - 50}
                  height={400}
                  data={
                    data?.usage?.statusCodes
                      ? Object.keys(data?.usage?.statusCodes)?.map((v) => ({
                          name: v,
                          value: data.usage.statusCodes[v],
                        }))
                      : []
                  }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <h3 className="content-table__title">Sections usage</h3>
                <RadarChart
                  width={smallSizes.width - 50}
                  height={400}
                  cx="50%"
                  cy="50%"
                  outerRadius="80%"
                  data={
                    data?.usage?.sections
                      ? Object.keys(data?.usage?.sections)?.map((v) => ({
                          name: v,
                          value: data.usage.sections[v],
                        }))
                      : []
                  }
                >
                  <Legend />

                  <PolarGrid />
                  <PolarAngleAxis dataKey="name" />
                  <PolarRadiusAxis />
                  <Radar
                    name="Methods"
                    dataKey="value"
                    stroke="#8884d8"
                    fill="#8884d8"
                    fillOpacity={0.6}
                  />
                </RadarChart>
              </div>
            </Col>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <h3 className="content-table__title">Endpoints usage</h3>
                <Treemap
                  width={smallSizes.width - 50}
                  height={400}
                  data={[
                    {
                      name: "endpoints",
                      children: data?.usage?.endpoints
                        ? Object.keys(data?.usage?.endpoints)?.map((v) => ({
                            name: v,
                            size: data.usage.endpoints[v],
                          }))
                        : [],
                    },
                  ]}
                  dataKey="size"
                  aspectRatio={4 / 3}
                  stroke="#fff"
                  fill="#8884d8"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <h3 className="content-table__title">Endpoints latency</h3>
                {data?.latency && Object.keys(data?.latency)?.length > 0 ? (
                  <PrBarChart
                    width={sizes.width - 50}
                    height={400}
                    data={
                      data?.latency
                        ? Object.keys(data?.latency)
                            ?.map((v) => ({
                              name: v,
                              average: data?.latency[v].average,
                              max: data?.latency[v].max,
                              min: data?.latency[v].min,
                            }))
                            .sort((a, b) => {
                              if (a.max === b.max) {
                                return 0;
                              }

                              return a.max > b.max ? -1 : 1;
                            })
                            .slice(0, 15)
                        : []
                    }
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <XAxis tickLine={false} axisLine={false} dataKey="name" />
                    <YAxis
                      padding={{ bottom: 20 }}
                      interval="preserveEnd"
                      tickLine={false}
                      axisLine={false}
                      domain={[
                        (dataMin) => dataMin - dataMin / 10,
                        (dataMax) => dataMax + dataMax / 10,
                      ]}
                    />
                    <Tooltip cursor={false} />
                    <Legend />
                    <Bar dataKey="max" fill={COLORS["DELETE"]} name="max" />
                    <Bar dataKey="min" fill={COLORS["POST"]} name="min" />
                    <Bar
                      dataKey="average"
                      fill={COLORS["GET"]}
                      name="average"
                    />
                  </PrBarChart>
                ) : (
                  <h6 style={{ marginTop: "3vh" }}>No hay datos disponibles</h6>
                )}
              </div>
            </Col>
          </Row>
        </Row>
      </Col>
    </>
  );
};

export { ApiPage };
