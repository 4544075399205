import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes';
import { UserContextProvider } from "./context/UserContext";
import './index.css'

ReactDOM.render(
  <UserContextProvider>
    <App />
  </UserContextProvider>,
  document.getElementById('root')
);