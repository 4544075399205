import {useCallback, useContext, useState} from 'react'
import UserContextProvider from '../context/UserContext'
import UserService from '../service/user';

export default function useUser () {
  const {session, setSession} = useContext(UserContextProvider)
  const [state, setState] = useState({loading: false, error: false, message: null})
	const userService = new UserService()

  const login = useCallback(({user, pass}) => {
    return new Promise((resolve, reject) => {
      setState({...state, loading: true, error: false })
      userService.login({user, pass})
        .then((response) => {
          setState({...state, loading: false, error: false })
          resolve({success: true})
        })
        .catch(err => {
          setState({...state, loading: false, error: true, message: err.message})
          reject({success: false})
        })
    })
  }, [setSession, session]) 

  const checkSession = useCallback(() => {
    setState({...state, loading: true, error: false })
    userService.checkSession()
      .then((response) => {
        setState(prev => ({...prev, loading: false, error: false}))
      })
      .catch(err => {
        setState({...state, loading: false, error: true, message: err.message})
      })
  }, [setSession, session]) 

  const logout = useCallback(() => {
    setSession({})
  }, [setSession, session])

  return {
    error: state.error,
    message: state.message,
    loading: state.loading,
    logout,
    login,
    checkSession,
    logged: Boolean(session.token),
    setError: (error, message) => {setState(prev => ({...prev, error, message}))},
    setLoading: loading => {setState(prev => ({...prev, loading}))}
  }
} 