import { useCallback, useState } from "react";
import OrganizationsService from "../service/organizations";

export default function useOrganizations() {
  const [state, setState] = useState({
    loading: false,
    error: false,
    message: null,
    data: [],
    detail: null,
  });
  const organizationsService = new OrganizationsService();

  const getResume = useCallback(() => {
    return new Promise((resolve, reject) => {
      setState({ ...state, loading: true, error: false });
      organizationsService
        .getResume()
        .then((response) => {
          setState({ ...state, loading: false, error: false, data: response });
          resolve({ success: true });
        })
        .catch((err) => {
          setState({
            ...state,
            loading: false,
            error: true,
            message: err.message,
          });
          reject({ success: false });
        });
    });
  }, [setState, state]);

  const getDetail = useCallback(
    (id) => {
      return new Promise((resolve, reject) => {
        setState({ ...state, loading: true, error: false });
        organizationsService
          .getDetail(id)
          .then((response) => {
            setState({
              ...state,
              loading: false,
              error: false,
              detail: response,
            });
            resolve({ success: true });
          })
          .catch((err) => {
            setState({
              ...state,
              loading: false,
              error: true,
              message: err.message,
            });
            reject({ success: false });
          });
      });
    },
    [setState, state]
  );

  return {
    error: state.error,
    message: state.message,
    loading: state.loading,
    data: state.data,
    detail: state.detail,
    getResume,
    getDetail,
    setError: (error, message) => {
      setState((prev) => ({ ...prev, error, message }));
    },
    setLoading: (loading) => {
      setState((prev) => ({ ...prev, loading }));
    },
  };
}
