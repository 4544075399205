import { useCallback, useState } from "react";
import RegistersService from "../service/registers";

export default function useRegisters() {
  const [state, setState] = useState({
    loading: false,
    error: false,
    message: null,
    data: {},
  });
  const registersService = new RegistersService();

  const getRegistersHours = useCallback(() => {
    return new Promise((resolve, reject) => {
      setState({ ...state, loading: true, error: false });
      registersService
        .getRegistersHours()
        .then((response) => {
          setState((prev) => ({
            ...prev,
            loading: false,
            error: false,
            data: { ...prev.data, hours: response },
          }));
          resolve({ success: true });
        })
        .catch((err) => {
          setState({
            ...state,
            loading: false,
            error: true,
            message: err.message,
          });
          reject({ success: false });
        });
    });
  }, [setState, state]);

  const getTotalRegisters = useCallback(() => {
    return new Promise((resolve, reject) => {
      setState({ ...state, loading: true, error: false });
      registersService
        .getTotalRegisters()
        .then((response) => {
          setState((prev) => ({
            ...prev,
            loading: false,
            error: false,
            data: { ...prev.data, total: response },
          }));
          resolve({ success: true });
        })
        .catch((err) => {
          setState({
            ...state,
            loading: false,
            error: true,
            message: err.message,
          });
          reject({ success: false });
        });
    });
  }, [setState, state]);

  const getWeekdaysRegisters = useCallback(() => {
    return new Promise((resolve, reject) => {
      setState({ ...state, loading: true, error: false });
      registersService
        .getWeekdaysRegisters()
        .then((response) => {
          setState((prev) => ({
            ...prev,
            loading: false,
            error: false,
            data: { ...prev.data, weekdays: response },
          }));
          resolve({ success: true });
        })
        .catch((err) => {
          setState({
            ...state,
            loading: false,
            error: true,
            message: err.message,
          });
          reject({ success: false });
        });
    });
  }, [setState, state]);

  return {
    error: state.error,
    message: state.message,
    loading: state.loading,
    data: state.data,
    getRegistersHours,
    getTotalRegisters,
    getWeekdaysRegisters,
    setError: (error, message) => {
      setState((prev) => ({ ...prev, error, message }));
    },
    setLoading: (loading) => {
      setState((prev) => ({ ...prev, loading }));
    },
  };
}
