import { useCallback, useState } from "react";
import SubscriptionsService from "../service/subscriptions";

export default function useSubscriptions() {
  const [state, setState] = useState({
    loading: false,
    error: false,
    message: null,
    data: {},
  });
  const subscriptionsService = new SubscriptionsService();

  const getPlans = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        setState({ ...state, loading: true, error: false });
        subscriptionsService
          .getPlans(params)
          .then((response) => {
            setState((prev) => ({
              ...prev,
              loading: false,
              error: false,
              data: { ...prev.data, plans: response },
            }));
            resolve({ success: true });
          })
          .catch((err) => {
            setState({
              ...state,
              loading: false,
              error: true,
              message: err.message,
            });
            reject({ success: false });
          });
      });
    },
    [setState, state]
  );

  const getOrgPlans = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        setState({ ...state, loading: true, error: false });
        subscriptionsService
          .getOrgPlans(params)
          .then((response) => {
            setState((prev) => ({
              ...prev,
              loading: false,
              error: false,
              data: { ...prev.data, orgs: response },
            }));
            resolve({ success: true });
          })
          .catch((err) => {
            setState({
              ...state,
              loading: false,
              error: true,
              message: err.message,
            });
            reject({ success: false });
          });
      });
    },
    [setState, state]
  );

  const updatePlans = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        setState({ ...state, loading: true, error: false });
        subscriptionsService
          .updatePlans(params)
          .then(() => {
            setState((prev) => ({
              ...prev,
              loading: false,
              error: false,
            }));
            resolve({ success: true });
          })
          .catch((err) => {
            setState({
              ...state,
              loading: false,
              error: true,
              message: err.message,
            });
            reject({ success: false });
          });
      });
    },
    [setState, state]
  );

  return {
    error: state.error,
    message: state.message,
    loading: state.loading,
    data: state.data,
    getPlans,
    getOrgPlans,
    updatePlans,
    setError: (error, message) => {
      setState((prev) => ({ ...prev, error, message }));
    },
    setLoading: (loading) => {
      setState((prev) => ({ ...prev, loading }));
    },
  };
}
