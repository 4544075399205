import Axios from "axios";
import { reviver } from "../utils";
import useToken from "./token";

export default function useApi() {
  const { setToken } = useToken();
  let axios;

  const init = () => {
    axios = Axios.create({
      baseURL: "/api/v1/",
      // timeout: 5000,
      transformResponse: [
        (data) => {
          try {
            return JSON.parse(data, reviver);
          } catch (error) {
            return data;
          }
        },
      ],
    });
  };

  const defaultErrorMessages = {
    400: "Algun dato no fue introducido",
    401: "La sesion no es valida",
    403: "No esta autorizado para realizar esta accion",
    404: "No se encontro la informacion solicitada",
    408: "Se alcanzo el tiempo maximo de espera, revise su conexion",
    409: "Se encontro un conflicto y la accion no pudo ser completada",
    429: "Se han enviado demasiadas solicitudes, por favor aguarde unos momentos",
    500: "Se produjo un error. Pero no es tu culpa! ya estamos trabajando para solucionarlo",
  };

  const defaultFatalErrors = [401, 404, 408];

  const request = (
    config,
    errorMessages = {},
    fatalErrors = defaultFatalErrors
  ) => {
    if (!axios) init();
    return new Promise((resolve, reject) => {
      const recoveredToken = localStorage.getItem("token");
      if (recoveredToken) {
        config.headers = {
          Authorization: `Bearer ${recoveredToken}`,
          ...config.headers,
        };
      }

      axios(config)
        .then(({ data: response }) => {
          if (response.token) setToken({ token: response.token });
          resolve(response.data);
        })
        .catch((err) => {
          if (!err.response)
            return reject({ fatal: true, message: "Sin conexion" });
          const fatal = fatalErrors.indexOf(err.response.status) !== -1;

          //If session is invalid (token expired, etc...)
          if (err.response.status === 401) setToken({ token: null });

          const customErrorMessages = {
            ...defaultErrorMessages,
            ...errorMessages,
          };

          if (!customErrorMessages[err.response.status]) {
            return reject({
              ...err.response,
              fatal,
              message: `Error desconocido [${err.response.status}] - ${err.response.data.error.txt}`,
            });
          }

          reject({
            ...err.response,
            fatal,
            message: customErrorMessages[err.response.status],
          });
        });
    });
  };

  return { request };
}
