import {
  AreaChart as PrAreachart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ReferenceLine,
  ComposedChart,
} from "recharts";

function AreaChart({ width, height, data, name, showWeekdays = false }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {data.length > 0 ? (
        <ComposedChart
          width={width ?? 500}
          height={height ?? 300}
          data={data}
          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis tickLine={false} axisLine={false} dataKey="name" />
          {showWeekdays &&
            data.map((v) => {
              const d = new Date(v.date).getDay();
              if (d === 0) {
                return (
                  <ReferenceLine
                    strokeWidth={4}
                    x={v.name}
                    stroke="#ff00004a"
                  />
                );
              }
              if (d === 6) {
                return (
                  <ReferenceLine
                    strokeWidth={4}
                    x={v.name}
                    stroke="#FFA5004a"
                  />
                );
              }
              return null;
            })}
          <YAxis
            padding={{ bottom: 20 }}
            interval="preserveEnd"
            tickLine={false}
            axisLine={false}
            domain={[
              (dataMin) => dataMin - dataMin / 10,
              (dataMax) => dataMax + dataMax / 10,
            ]}
          />
          <Tooltip cursor={false} />
          <Line
            connectNulls
            strokeWidth={1.5}
            legendType="plainline"
            type="monotone"
            dataKey="average"
            stroke="#F1490E"
            name={"Promedio semanal"}
            dot={null}
          />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#4C399F"
            fill="#4C399FE0"
            name={name ?? "Valor"}
          />
        </ComposedChart>
      ) : (
        <h6 style={{ marginTop: "3vh" }}>No hay datos disponibles</h6>
      )}
    </div>
  );
}

export { AreaChart };
