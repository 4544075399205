import { useState } from "react";
import { Outlet } from "react-router-dom";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import useUser from "../../../hooks/useUser";
import Sidebar from "../components/Sidebar";
import "./styles.css";
import SidebarItem from "../components/Sidebar/components/SidebarItem";
import {
  Activity,
  Building,
  CashStack,
  Clipboard,
  Grid1x2Fill,
  Person,
} from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const [sidebar, setSidebar] = useState(true);
  const { logout, loading } = useUser();
  let location = useLocation().pathname;

  function hamburgerToggleSidebar() {
    setSidebar(!sidebar);
  }

  return (
    <>
      <Sidebar
        sidebar={sidebar}
        handleLogout={logout}
        handleToggleSidebar={hamburgerToggleSidebar}
      >
        <SidebarItem
          opened={sidebar}
          active={location}
          icon={<Grid1x2Fill size={20} />}
          to="dashboard"
          text="Dashboard"
        />
        <SidebarItem
          opened={sidebar}
          active={location}
          icon={<Building size={20} />}
          to="dashboard/organizations"
          text="Empresas"
        />
        <SidebarItem
          opened={sidebar}
          active={location}
          icon={<Building size={20} />}
          to="dashboard/organization-detail"
          text="Detalle Empresa"
        />
        <SidebarItem
          opened={sidebar}
          active={location}
          icon={<Person size={20} />}
          to="dashboard/accounts"
          text="Cuentas"
        />
        <SidebarItem
          opened={sidebar}
          active={location}
          icon={<Clipboard size={20} />}
          to="dashboard/registers"
          text="Fichadas"
        />
        <SidebarItem
          opened={sidebar}
          active={location}
          icon={<Activity size={20} />}
          to="dashboard/api"
          text="API"
        />
        <SidebarItem
          opened={sidebar}
          active={location}
          icon={<CashStack size={20} />}
          to="dashboard/subscriptions"
          text="Suscripciones"
        />
      </Sidebar>

      <div
        className={`${
          sidebar ? "dashboard-template__main-container-sidebar-open" : ""
        } dashboard-template__main-container`}
      >
        <div className="dashboard-template__header">
          {loading ? (
            <SkeletonTheme baseColor="#8071c0" highlightColor="#ffffff3b ">
              <Skeleton style={{ width: "30%" }} />
            </SkeletonTheme>
          ) : (
            "Praesenti monitor"
          )}
        </div>
        <div className="dashboard-template__content">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export { Layout };
