import adapter from '../api'

class DashboardService {
  constructor(){
    this.axios = adapter().request
  }

  async getResume() {
    return new Promise( async (resolve, reject) => {
      this.axios({
        method: 'get',
        url: 'dashboard',
      })
      .then(resolve)
      .catch(reject)
    })
  }


}

export default DashboardService;