import React from 'react';
import './styles.css'
import Logo from '../../assets/images/logo.png'
import { Button } from '../Button'
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import useUser from '../../hooks/useUser'

function Header() {
  const {logged, logout} = useUser()

  return (
    <>
      <div className="header">
        <Container className="header__container">
          <Link to="">
            <img src={Logo} className="header__image" alt="Logo"/>
          </Link>
          
          <div className="header__button-container">
            {
              !logged ?
              (
                <>
                  <Link to="/login" className="header__button">
                    <Button filled={false} text="Iniciar Sesion"/>
                  </Link>
                  <div className="header__button-separator"/>
                </>
              ) : (
                <>
                  <Button onClick={logout} filled={false} text="Cerrar Sesion"/>
                  <div className="header__button-separator"/>
                  <Link to="/dashboard" className="header__button">
                    <Button filled={true} text="Ir al Dashboard"/>
                  </Link>
                </>
              )

            }
            
          </div> 
        </Container>
      </div>
      <div className="header__separator"></div>
    </>
  );
}

export { Header };

