import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Building, Clipboard, Clock, Person, PersonCheck, PersonFill } from "react-bootstrap-icons";
import { LineChart } from "../components/LineChart";
import useDashboard from "../../../hooks/useDashboard";
import { DashboardCounter } from "../components/DashboardCounter";
import { PieGraph } from "../components/PieGraph";
import '../styles.css'

const Dashboard = () => { 
  const [lastDays, setLastDays] = useState([])
  const {loading, error, message, data, getResume} = useDashboard()

useEffect(() => {
  getResume()

  const tempLastDays = []
  const TwentyFourHours = 1000*60*60*24
  const now = new Date()
  const today = new Date(`${now.getMonth()+1}/${now.getDate()}/${now.getFullYear()}`);
  
  for(let i = 0; i < 7; i++) {
    const yesterday = new Date(today.valueOf() - TwentyFourHours*(i));
  
    tempLastDays.push({
      name: (yesterday.getDay() == today.getDay()) ? 'Hoy' : `${yesterday.getDate()}/${yesterday.getMonth()+1}`,
      value: Math.trunc(Math.random() * (1600 - 1) + 1)
    })
  }
  
  setLastDays(tempLastDays.reverse())
}, [])

  return (
    <>
      <Col>
        <Row>
          <div className="dashboard__indicators-section">
            <DashboardCounter title="Registros" counter={data.registers_count}><Clipboard size={40}/></DashboardCounter>
            <DashboardCounter title="Empleados" counter={data.employees}><Person size={40}/></DashboardCounter>
            <DashboardCounter title="Empleados Registrados" counter={data.registered_employees}><PersonCheck size={40}/></DashboardCounter>
            <DashboardCounter title="Horas registradas" counter={data.hours}><Clock size={40}/></DashboardCounter>
            <DashboardCounter title="Empresas registradas" counter={data.organizations}><Building size={40}/></DashboardCounter>
            <DashboardCounter title="Cuentas registradas" counter={data.users}><PersonFill size={40}/></DashboardCounter>
          </div>
        </Row>
        <Row >
          <Row>
              <Col className="dashboard__graph-col">
                <div className="dashboard__card">
                  <h3 className="content-table__title">Empleados no registrados / Empleados registrados</h3>
                  <PieGraph data={true ? [
                      { name: "Registrados", value: data.registered_employees },
                      { name: "No registrados", value: data.employees - data.registered_employees },
                    ]: []} 
                  />
                </div>
              </Col>
              <Col className="dashboard__graph-col">
                <div className="dashboard__card">
                  <h3 className="content-table__title">Cantidad de registros (ultimos 7 dias)</h3>
                  <LineChart data={data.registers ?? []} />
                </div>
              </Col>
          </Row>
          <Row>
              <Col className="dashboard__graph-col">
                <div className="dashboard__card">
                  <h3 className="content-table__title">Uptime (ultimos 7 dias)</h3>
                  <LineChart data={data.uptime ?? []} />
                </div>
              </Col>
              <Col className="dashboard__graph-col">
                <div className="dashboard__card">
                  <h3 className="content-table__title">Latencia (ultimos 7 dias)</h3>
                  <LineChart data={data.latency ?? []} />
                </div>
              </Col>
          </Row>
        </Row>
      </Col>
    </>
  )
}

export {Dashboard};