import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import "../styles.css";
import useSubscriptions from "../../../hooks/useSubscriptions";
import { InputText } from "../../../components/InputText";
import { ArrowRight, ExclamationTriangleFill } from "react-bootstrap-icons";

const UpdatePlansPage = () => {
  const { data, setError, error, loading, message, getPlans, updatePlans } =
    useSubscriptions();
  const [formData, setFormData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    getPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newData = data?.plans?.map((v) => {
      const d = {
        id: v.id,
        name: v.name,
        price: v.price,
        employees: v.employees,
      };
      return { ...d, default: d };
    });
    setFormData(newData);
  }, [data?.plans]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowConfirm(true);
    setError(false);
  };

  const onChange = (id, field, newValue) => {
    setFormData((prev) => {
      const i = prev.findIndex((v) => v.id === id);
      const cp = structuredClone(prev);
      cp[i][field] = newValue;
      return cp;
    });
  };

  const sendData = async (data) => {
    const formattedData = data.map((v) => ({
      id: v.id,
      name: v.name,
      price: v.price,
      employees: v.employees,
    }));

    await updatePlans(formattedData);
    getPlans();
    setShowConfirm(false);
  };

  return (
    <>
      <Col>
        <Modal
          centered
          size="lg"
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Confirmacion de modificacion</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && <Alert variant="danger">{message}</Alert>}
            <span>Revisa con cuidado los cambios</span>

            {formData &&
              formData.map((pl) => (
                <div
                  style={{
                    borderLeft: "1px solid #ccc",
                    padding: "1rem",
                    background: "#cccccc2a",
                    marginBottom: "1rem",
                  }}
                >
                  {pl.name !== pl.default.name && (
                    <div>
                      <b>Nombre: {"  "}</b>
                      {pl.default.name} <ArrowRight /> {pl.name}
                    </div>
                  )}

                  {pl.employees !== pl.default.employees && (
                    <div>
                      <b>Empleados: {"  "}</b>
                      {pl.default.employees} <ArrowRight /> {pl.employees}
                    </div>
                  )}

                  {pl.price !== pl.default.price && (
                    <div>
                      <b>Precio: {"  "}</b>${pl.default.price} <ArrowRight /> $
                      {pl.price}
                    </div>
                  )}
                </div>
              ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirm(false)}>
              Cancelar
            </Button>
            <Button
              variant="success"
              loading={loading}
              onClick={() => sendData(formData)}
            >
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>

        <Row>
          <Row>
            <Col className="dashboard__graph-col">
              <div className="dashboard__card">
                <Row>
                  <Alert variant="warning">
                    Al modificar los campos que contienen el icono{" "}
                    <ExclamationTriangleFill color="#FFBB28" /> se notificara a
                    los usuarios que tengan ese plan y la modificacion sera
                    aplicada en 10 dias
                  </Alert>
                  <Alert variant="primary">
                    Al modificar los campos que contienen el icono{" "}
                    <ExclamationTriangleFill color="#0088FE" /> se notificara a
                    los usuarios que tengan ese plan y la modificacion sera
                    aplicada inmediatamente.{" "}
                    <b>
                      EN CASO DE BAJAR LA CANTIDAD DE EMPLEADOS, PODRIA HABER
                      EMPRESAS QUE TERMINEN TENIENDO MAS EMPLEADOS DE LA MAXIMA
                    </b>
                  </Alert>
                </Row>
                <form onSubmit={handleSubmit}>
                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <h3
                      style={{ flexGrow: "999" }}
                      className="content-table__title"
                    >
                      Planes
                    </h3>
                    <Button type="submit" variant="success">
                      Guardar
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {formData?.map((pl, i) => {
                      return (
                        <div
                          style={{
                            borderLeft: "1px solid #ccc",
                            padding: "1rem",
                            background: "#cccccc2a",
                            marginBottom: "1rem",
                          }}
                        >
                          <Row>
                            <Col className="col-6">
                              <InputText
                                onChange={(e) =>
                                  onChange(pl.id, "name", e.target.value)
                                }
                                value={pl.name}
                                name="name"
                                label="Nombre"
                              />
                            </Col>
                            <Col>
                              <InputText
                                inputStyle={
                                  pl.employees !== pl.default.employees && {
                                    background: "#cfe2ff",
                                  }
                                }
                                onChange={(e) =>
                                  onChange(
                                    pl.id,
                                    "employees",
                                    parseInt(e.target.value)
                                  )
                                }
                                value={pl.employees}
                                name="employees"
                                label={
                                  <>
                                    Empleados{" "}
                                    <ExclamationTriangleFill color="#0088FE" />
                                  </>
                                }
                              />
                            </Col>
                            <Col>
                              <InputText
                                inputStyle={
                                  pl.price !== pl.default.price && {
                                    background: "#fff3cd",
                                  }
                                }
                                onChange={(e) =>
                                  onChange(
                                    pl.id,
                                    "price",
                                    parseInt(e.target.value)
                                  )
                                }
                                value={pl.price === 0 ? "GRATIS" : pl.price}
                                name="price"
                                label={
                                  <>
                                    Precio{" "}
                                    <ExclamationTriangleFill color="#FFBB28" />
                                  </>
                                }
                                disabled={pl.price === 0}
                              />
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Row>
      </Col>
    </>
  );
};

export { UpdatePlansPage };
