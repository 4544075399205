import {useCallback, useContext} from 'react'
import UserContextProvider from '../context/UserContext'

export default function useToken () {
  const {setSession} = useContext(UserContextProvider)

  const setToken = useCallback(({token}) => {
    if(setSession) setSession(prev =>  ({...prev, token}))
  }, [setSession]) 


  return {
    setToken
  }
} 