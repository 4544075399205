import adapter from "../api";

class RegistersService {
  constructor() {
    this.axios = adapter().request;
  }

  async getRegistersHours() {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "registers/hours",
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async getTotalRegisters() {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "registers/total",
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async getWeekdaysRegisters() {
    return new Promise(async (resolve, reject) => {
      this.axios({
        method: "get",
        url: "registers/weekdays",
      })
        .then(resolve)
        .catch(reject);
    });
  }
}

export default RegistersService;
